import React, {
  useState,
  useEffect,
  useMemo,
  memo,
  useCallback,
  useRef,
} from 'react';
import {
  Map,
  Pane,
  TileLayer,
  ScaleControl,
  ZoomControl,
  LayersControl,
  LayerGroup,
  FeatureGroup,
  Marker,
  GeoJSON,
  // Popup,
  Tooltip,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import classNames from 'classnames';
import L from 'leaflet';
import { wgs84togcj02 } from 'coordtransform';
import {
  batchLoadTyphooneDetail,
  loadTyphoonList,
  loadHydroStationList,
} from './service';
import useModel from './model';
import TyphooLevelStyle from './TyphoonLevelStyle';
import TyphonePath from './TyphoonPath';
import CoordinatesControl from './CoordinatesControl';
import CustomControl from './CustomControl';
import TyphoonLegend from './TyphoonLegend';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './App.css';
import FloodLayer from './FloodLayer';
import SearchPanel from './SearchPanel';

L.drawLocal = {
  // format: {
  // 	numeric: {
  // 		delimiters: {
  // 			thousands: ',',
  // 			decimal: '.'
  // 		}
  // 	}
  // },
  draw: {
    toolbar: {
      // #TODO: this should be reorganized where actions are nested in actions
      // ex: actions.undo  or actions.cancel
      actions: {
        title: '结束标注',
        text: '取消',
      },
      finish: {
        title: '完成标注',
        text: '完成',
      },
      undo: {
        title: '撤销最后绘制的点',
        text: '撤销',
      },
      buttons: {
        polyline: '标注线',
        polygon: '标注面',
        rectangle: '标注矩形',
        circle: '标注圆形',
        marker: '标注点',
        circlemarker: '标注圆点',
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: '点击并拖动绘制圆形',
        },
        radius: '半径',
      },
      circlemarker: {
        tooltip: {
          start: '点击地图标注圆点',
        },
      },
      marker: {
        tooltip: {
          start: '点击地图标注点',
        },
      },
      polygon: {
        tooltip: {
          start: '单击开始标注面',
          cont: '单击继续绘制形状',
          end: '再次点击起点结束标注',
        },
      },
      polyline: {
        error: '<strong>错误:</strong> 图形出现自相交!',
        tooltip: {
          start: '单击开始标注线',
          cont: '单击继续绘制线',
          end: '再次点击最后绘制的点结束标注',
        },
      },
      rectangle: {
        tooltip: {
          start: '点击并拖拽以绘制矩形',
        },
      },
      simpleshape: {
        tooltip: {
          end: '松开鼠标结束绘制',
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: '保存更改',
          text: '保存',
        },
        cancel: {
          title: '退出编辑，还原修改',
          text: '取消',
        },
        clearAll: {
          title: '清除所有标注',
          text: '清空',
        },
      },
      buttons: {
        edit: '编辑标注',
        editDisabled: '无可编辑的标注',
        remove: '删除标注',
        removeDisabled: '无可删除的标注',
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: '拖动节点编辑标注',
          subtext: '点击取消还原修改',
        },
      },
      remove: {
        tooltip: {
          text: '单击标注进行删除',
        },
      },
    },
  },
};

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/marker-icon-2x.png',
  iconUrl: '/marker-icon.png',
  shadowUrl: '/marker-shadow.png',
});

L.GridLayer.include({
  _setZoomTransform: function (level, _center, zoom) {
    var center = _center;
    if (center !== undefined && this.options) {
      if (this.options.corrdType === 'gcj02') {
        const c = wgs84togcj02(_center.lng, _center.lat);
        center = {
          lng: c[0],
          lat: c[1],
        };
      }
    }
    var scale = this._map.getZoomScale(zoom, level.zoom),
      translate = level.origin
        .multiplyBy(scale)
        .subtract(this._map._getNewPixelOrigin(center, zoom))
        .round();

    if (L.Browser.any3d) {
      L.DomUtil.setTransform(level.el, translate, scale);
    } else {
      L.DomUtil.setPosition(level.el, translate);
    }
  },
  _getTiledPixelBounds: function (_center) {
    var center = _center;
    if (center !== undefined && this.options) {
      if (this.options.corrdType === 'gcj02') {
        const c = wgs84togcj02(_center.lng, _center.lat);
        center = {
          lng: c[0],
          lat: c[1],
        };
        // center = L.coordConver().gps84_To_gcj02(_center.lng, _center.lat);
      }
    }
    var map = this._map,
      mapZoom = map._animatingZoom
        ? Math.max(map._animateToZoom, map.getZoom())
        : map.getZoom(),
      scale = map.getZoomScale(mapZoom, this._tileZoom),
      pixelCenter = map.project(center, this._tileZoom).floor(),
      halfSize = map.getSize().divideBy(scale * 2);

    return new L.Bounds(
      pixelCenter.subtract(halfSize),
      pixelCenter.add(halfSize)
    );
  },
});

const searchIcon = new L.Icon.Default({ imagePath: '/' });

const MAP_OPTION = {
  center: [35, 110],
  zoom: 5,
};

// const BASE_MAP_CONFIG = {
//   url:
//     "https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=8&x={x}&y={y}&z={z}",
//   subdomains: "1234",
//   tileSize: 256,
//   minZoom: 3,
//   maxZoom: 18,
//   detectRetina: true
// };

const BASE_MAP_CONFIG_GOOGLE_SAT = {
  url: 'https://tile.herisk.com/tile/google_sat/{z}/{x}/{y}.jpg',
  // url: 'https://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  corrdType: 'gcj02',
  // detectRetina: true
};
const BASE_MAP_CONFIG_GOOGLE_TER = {
  url: 'https://tile.herisk.com/tile/google_ter/{z}/{x}/{y}.jpg',
  // url: 'https://gac-geo.googlecnapps.cn/maps/vt?lyrs=p@189&gl=cn&x={x}&y={y}&z={z}',
  // url: '//p{s}.map.gtimg.com/demTiles/{z}/{sx}/{sy}/{x}_{-y}.jpg',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  corrdType: 'gcj02',
  // detectRetina: true
};
const BASE_MAP_CONFIG_MAPBOX_TER = {
  url: 'https://tile.herisk.com/tile/mapbox_ter/{z}/{x}/{y}.png',
  // url: 'https://api.mapbox.com/styles/v1/tigerlihao/clllzahnp011e01qpckoya4rv/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidGlnZXJsaWhhbyIsImEiOiJjbGxsdG44cGEyZWduM2RuMTcwNHp6M3N5In0.749bCGW_FTXcTE3WNbE9zg',
  tileSize: 512,
  minZoom: 3,
  maxZoom: 18,
  zoomOffset: -1,
  // detectRetina: true
};
const BASE_MAP_CONFIG_MAPBOX_SAT = {
  url: 'https://tile.herisk.com/tile/mapbox_sat/{z}/{x}/{y}.jpg',
  tileSize: 512,
  minZoom: 3,
  maxZoom: 18,
  zoomOffset: -1,
  // detectRetina: true
};
const BASE_MAP_CONFIG_TDT_IMG = {
  // url: 'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // url: 'https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // subdomains: "01234567",
  url: 'https://tile.herisk.com/tile/tdt_img/{z}/{x}/{y}.jpg',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  // detectRetina: true
};
const BASE_MAP_CONFIG_TDT_CIA = {
  // url: 'https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // url: 'https://t{s}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // subdomains: "01234567",
  url: 'https://tile.herisk.com/tile/tdt_cia/{z}/{x}/{y}.png',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  // detectRetina: true
};
const BASE_MAP_CONFIG_TDT_VEC = {
  // url: 'https://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // url: 'https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // subdomains: "01234567",
  url: 'https://tile.herisk.com/tile/tdt_vec/{z}/{x}/{y}.png',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  // detectRetina: true
};
const BASE_MAP_CONFIG_TDT_CVA = {
  // url: 'https://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // url: 'https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=4c4e154adeaf7aa9fcc140ba8cc2ee1e',
  // subdomains: "01234567",
  url: 'https://tile.herisk.com/tile/tdt_cva/{z}/{x}/{y}.png',
  tileSize: 256,
  minZoom: 3,
  maxZoom: 18,
  // detectRetina: true
};
const BASE_MAP_CONFIG_TOPO = {
  url: 'https://tile.herisk.com/tile/landscape/{z}/{x}/{y}.png',
  tileSize: 256,
  minZoom: 13,
  maxZoom: 17,
  // detectRetina: true
};

const BASE_MAP_CONFIG_FLOOD_RISK = {
  url: 'https://tile.herisk.com/tile/flood_risk/{z}/{x}/{y}.png',
  tileSize: 256,
  maxNativeZoom: 13,
  // maxZoom: 15,
  bounds: [
    [19, 109],
    [26, 118],
  ],
  opacity: 0.8,
};

const BASE_MAP_CONFIG_TY_RISK = {
  url: 'https://tile.herisk.com/tile/ty_risk/{z}/{x}/{y}.png',
  tileSize: 256,
  minZoom: 5,
  maxNativeZoom: 10,
  bounds: [
    [10, 95],
    [50.2, 135.2],
  ],
  opacity: 0.5,
};

const TyphoonInfo = memo(({ data = {}, onClick = () => {} }) => {
  const { id6: id, nameZh, nameEn } = data;
  if (!id) {
    return null;
  }
  return (
    <div className="TyCurrent" onClick={onClick}>
      <div className="TyName">
        {id} {nameZh || nameEn || ''}
      </div>
      <div>返回列表</div>
    </div>
  );
});

const YEARS_LIST = [];
for (let year = 1950; year < new Date().getFullYear(); year++) {
  YEARS_LIST.push(year);
}

const YEAR_OPTIONS = YEARS_LIST.map((year) => (
  <option key={year} value={year}>
    {year}年
  </option>
));

const LEVEL_OPTIONS = ['TD', 'TS', 'STS', 'TY', 'STY', 'SuperTY'].map(
  (level) => (
    <option key={level} value={level}>
      {TyphooLevelStyle[level].name}({level})
    </option>
  )
);

function App() {
  const [state, dispatch] = useModel();
  const [showCurrentTyphoon, setShowCurrentTyphoon] = useState(true);
  const [topic, setTopic] = useState('');
  const {
    startYear,
    endYear,
    minLevel,
    showMask,
    selected,
    data,
    loading,
    TY_IDS,
    current,
    hydroStations,
    searchResult,
    currentKeyword,
    currentPOI,
  } = state;
  const showLine = showMask & 1;
  const showMarker = showMask & 2;
  useEffect(() => {
    dispatch({ type: 'loadTyphoonDatas' });
    loadTyphoonList().then((list) => {
      dispatch({ type: 'saveTyphoonList', payload: list });
    });
  }, [dispatch]);
  useEffect(() => {
    loadHydroStationList().then((list) => {
      dispatch({ type: 'saveHydroStationList', payload: list });
    });
  }, [dispatch]);
  const tyIds = useMemo(
    () =>
      showCurrentTyphoon
        ? current
        : (TY_IDS[minLevel] || []).filter((id) => {
            const year = parseInt(id.slice(0, 4));
            return year && year >= startYear && year <= endYear;
          }),
    [startYear, endYear, minLevel, TY_IDS, current, showCurrentTyphoon]
  );
  const mapClick = useCallback(() => {
    if (selected) {
      dispatch({ type: 'unselect' });
    }
  }, [dispatch, selected]);
  useEffect(() => {
    const noDataIds = tyIds.filter((id) => !data[id]);
    if (noDataIds.length > 0) {
      dispatch({ type: 'loadTyphoonDatas' });
      batchLoadTyphooneDetail(noDataIds).then((datas) => {
        dispatch({ type: 'saveTyphoonDatas', payload: datas });
      });
    }
  }, [data, dispatch, tyIds]);
  const mapRef = useRef();
  // 自动变量刷新瓦片
  // useEffect(() => {
  //   if (hydroStations.length < 1) {
  //     return;
  //   }
  //   let i = 0;
  //   const interval = setInterval(() => {
  //     const { lat, lon } = hydroStations[i];
  //     mapRef.current.leafletElement.panTo([lat, lon]);
  //     i += 1;
  //     if (i >= hydroStations.length) {
  //       i = 0;
  //     }
  //   }, 5000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [hydroStations]);
  const [editing, setEditing] = useState(false);
  const [markers, setMarkers] = useState(
    JSON.parse(localStorage.getItem('markers') || '[]')
  );
  const [markerVersion, setMarkerVersion] = useState(0);

  const onEditorLayerLoaded = (e) => {
    // console.log(e);
    const savedMarkers = [];
    JSON.parse(localStorage.getItem('markers') || '[]').forEach((item) => {
      const m = L.GeoJSON.geometryToLayer(item, {
        pane: 'custom-marker-edit',
        pointToLayer: (feature, latlng) =>
          L.circleMarker(latlng, {
            radius: 6,
            pane: 'custom-marker-edit',
            color: '#FF6600',
            weight: 4,
            opacity: 0.9,
          }),
        color: '#FF0000',
        weight: 4,
        opacity: 0.9,
      }).addTo(e.target);
      const g = m.toGeoJSON();
      g.id = L.stamp(m);
      savedMarkers.push(g);
    });
    setMarkers(savedMarkers);
    // e.target.add();
    // console.log('onMapLoad');
    // mapRef.current.leafletElement.invalidateSize();
    // 加载标注
    // 添加到编辑图层，获取到ID
    // 更新markers
  };

  useEffect(() => {
    localStorage.setItem('markers', JSON.stringify(markers));
  }, [markers]);

  const toggleSearch = useCallback(() => {
    setTopic((old) => (old === 'SEARCH' ? '' : 'SEARCH'));
  }, []);
  const toggleTyphoon = useCallback(() => {
    setTopic((old) => (old === 'TY' ? '' : 'TY'));
  }, []);
  const toggleFlood = useCallback(() => {
    setTopic((old) => (old === 'FL' ? '' : 'FL'));
  }, []);
  return (
    <div className="App">
      <Map
        ref={mapRef}
        className="Map"
        {...MAP_OPTION}
        attributionControl={false}
        zoomControl={false}
        onClick={mapClick}
        onBaselayerchange={(e) => {
          dispatch({ type: 'changeBaseMap', payload: e.name });
          // if(e.name === '谷歌地形图') {
          //   // 使用gcj2000
          // } else {
          //   // 使用wgs84
          // }
        }}
      >
        <Pane
          name="typhoon-line"
          className={classNames({
            pane_hidden: !showLine,
            pane_dim: !!selected,
          })}
        />
        <Pane
          name="typhoon-marker"
          className={classNames({
            pane_hidden: !showMarker,
            pane_dim: !!selected,
          })}
        />
        <Pane
          name="typhoon-line-selected"
          className={showLine ? '' : 'pane_hidden'}
        />
        <Pane
          name="typhoon-marker-selected"
          className={showMarker ? '' : 'pane_hidden'}
        />
        <Pane
          name="custom-marker-edit"
          className={editing ? '' : 'pane_hidden'}
        />
        <Pane name="custom-marker" className={editing ? 'pane_hidden' : ''} />
        {/* <Pane name="sat_label" /> */}
        <ScaleControl imperial={false} position="bottomleft" />
        <CoordinatesControl position="bottomright" />
        <LayersControl position="topright" collapsed={false}>
          {/* <LayersControl.BaseLayer checked name="卫星图">
            <TileLayer {...BASE_MAP_CONFIG} />
          </LayersControl.BaseLayer> */}
          <LayersControl.BaseLayer checked name="谷歌地形图">
            <TileLayer {...BASE_MAP_CONFIG_GOOGLE_TER} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="谷歌卫星图">
            <LayerGroup>
              <TileLayer {...BASE_MAP_CONFIG_GOOGLE_SAT} />
              <TileLayer {...BASE_MAP_CONFIG_TDT_CIA} />
            </LayerGroup>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Mapbox地形图">
            <TileLayer {...BASE_MAP_CONFIG_MAPBOX_TER} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Mapbox卫星图">
            <LayerGroup>
              <TileLayer {...BASE_MAP_CONFIG_MAPBOX_SAT} />
              <TileLayer {...BASE_MAP_CONFIG_TDT_CIA} />
            </LayerGroup>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="天地图卫星图">
            <LayerGroup>
              <TileLayer {...BASE_MAP_CONFIG_TDT_IMG} />
              <TileLayer {...BASE_MAP_CONFIG_TDT_CIA} />
            </LayerGroup>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="天地图矢量图">
            <LayerGroup>
              <TileLayer {...BASE_MAP_CONFIG_TDT_VEC} />
              <TileLayer {...BASE_MAP_CONFIG_TDT_CVA} />
            </LayerGroup>
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="等高线地形图">
            <TileLayer {...BASE_MAP_CONFIG_TOPO} />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay checked={topic === 'TY'} name="台风路径">
            <LayerGroup>
              {tyIds
                .filter((tyId) => tyId !== selected)
                .map((tyId) => (
                  <TyphonePath
                    key={tyId}
                    id={tyId}
                    data={data[tyId]}
                    selected={false}
                    dispatch={dispatch}
                  />
                ))}
              {selected ? (
                <TyphonePath
                  key="selected"
                  id={selected}
                  data={data[selected]}
                  selected
                  dispatch={dispatch}
                />
              ) : null}
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="台风风险图">
            <TileLayer {...BASE_MAP_CONFIG_TY_RISK} />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked={topic === 'FL'} name="水文站点">
            <FloodLayer hydroStations={hydroStations} />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="洪涝风险图">
            <TileLayer {...BASE_MAP_CONFIG_FLOOD_RISK} />
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="自定义标记">
            <LayerGroup>
              {/* 直接GeoJSON图层 用key强制更新，会在图层控制上加很多层 */}
              <GeoJSON
                key={markerVersion}
                data={{ type: 'FeatureCollection', features: markers }}
                pane="custom-marker"
                pointToLayer={(feature, latlng) =>
                  L.circleMarker(latlng, {
                    radius: 6,
                  })
                }
                style={(x) =>
                  x.geometry.type === 'Point'
                    ? {
                        color: '#FF6600',
                        weight: 4,
                        opacity: 0.9,
                        pane: 'custom-marker',
                      }
                    : {
                        color: '#FF0000',
                        weight: 4,
                        opacity: 0.9,
                      }
                }
              />
            </LayerGroup>
            {/* <FeatureGroup pane="custom-marker">
              {markers.map((item) => (
                <GeoJSON
                  key={`${item.id}_${item.version}`}
                  data={item}
                  pane="custom-marker"
                />
              ))}
            </FeatureGroup> */}
          </LayersControl.Overlay>
        </LayersControl>
        {currentPOI && currentPOI.location ? (
          <Marker position={currentPOI.location} icon={searchIcon}>
            <Tooltip>
              <strong>{currentPOI.name}</strong>
              <br />
              {currentPOI.district}
              <br />
              {currentPOI.address}
            </Tooltip>
          </Marker>
        ) : null}
        <ZoomControl position="topright" />
        <FeatureGroup pane="custom-marker-edit" onAdd={onEditorLayerLoaded}>
          <EditControl
            position="topright"
            onEdited={(e) => {
              setMarkers((old) => {
                const editedLayers = e.layers;
                const editedMarkers = editedLayers.getLayers().map((layer) => {
                  const data = layer.toGeoJSON();
                  data.id = L.stamp(layer);
                  return data;
                });
                const newMarkers = old.map((item) => {
                  const edited = editedMarkers.find(
                    (editedItem) => editedItem.id === item.id
                  );
                  if (edited) {
                    return edited;
                  }
                  return item;
                });
                return newMarkers;
              });
              setMarkerVersion((old) => old + 1);
            }}
            onCreated={(e) => {
              setMarkers((old) => {
                const data = e.layer.toGeoJSON();
                data.id = L.stamp(e.layer);
                data.version = 0;
                return [...old, data];
              });
              setMarkerVersion((old) => old + 1);
            }}
            onDeleted={(e) => {
              setMarkers((old) => {
                const deletedIds = e.layers
                  .getLayers()
                  .map((layer) => L.stamp(layer));
                return old.filter((item) => !deletedIds.includes(item.id));
              });
              setMarkerVersion((old) => old + 1);
            }}
            // onMounted={this._onMounted}
            onDrawStart={() => {
              setEditing(true);
            }}
            onDrawStop={() => {
              setEditing(false);
            }}
            onEditStart={() => {
              setEditing(true);
            }}
            onEditStop={() => {
              setEditing(false);
            }}
            onDeleteStart={() => {
              setEditing(true);
            }}
            onDeleteStop={() => {
              setEditing(false);
            }}
            draw={{
              polyline: {
                shapeOptions: {
                  pane: 'custom-marker-edit',
                  color: '#FF0000',
                  weight: 4,
                  opacity: 0.8,
                },
              },
              polygon: {
                allowIntersection: false,
                shapeOptions: {
                  pane: 'custom-marker-edit',
                  color: '#FF0000',
                  weight: 4,
                  opacity: 0.8,
                },
              },
              circlemarker: {
                pane: 'custom-marker-edit',
                color: '#FF6600',
                weight: 4,
                opacity: 0.8,
                radius: 6,
                // repeatMode: true,
              },
              rectangle: false,
              circle: false,
              marker: false,
            }}
            edit={
              {
                // edit: false,
                // remove: false,
              }
            }
          />
        </FeatureGroup>
        <CustomControl position="bottomright" className="leaflet-bar">
          <TyphoonLegend />
        </CustomControl>
      </Map>
      <div className="OverlayContainer">
        {topic === 'TY' ? (
          <div className="Panel">
            <div className="TyphoonSelect">
              <span
                className={classNames({ selected: showCurrentTyphoon })}
                onClick={() => {
                  dispatch({ type: 'unselect' });
                  setShowCurrentTyphoon(true);
                }}
              >
                当前台风
              </span>
              <span
                className={classNames({ selected: !showCurrentTyphoon })}
                onClick={() => {
                  dispatch({ type: 'unselect' });
                  setShowCurrentTyphoon(false);
                }}
              >
                历史台风
              </span>
            </div>
            {showCurrentTyphoon ? (
              <div>
                显示样式：
                <select
                  value={showMask}
                  onChange={(e) => {
                    const newMask = parseInt(e.target.value);
                    if (newMask) {
                      dispatch({ type: 'changeShowMask', payload: newMask });
                    }
                  }}
                >
                  <option value={3}>点+线</option>
                  <option value={1}>只显示线</option>
                  <option value={2}>只显示点</option>
                </select>
                <br />
                <input
                  type="checkbox"
                  id="showMarker"
                  checked={showMarker}
                  onChange={() => {
                    dispatch({ type: 'toggleShowMarker' });
                  }}
                />
                <label htmlFor="showMarker">显示点</label>
                <input
                  type="checkbox"
                  id="showLine"
                  checked={showLine}
                  onChange={() => {
                    dispatch({ type: 'toggleShowLine' });
                  }}
                />
                <label htmlFor="showLine">显示线</label>
              </div>
            ) : (
              <div>
                时间范围：
                <select
                  value={startYear}
                  onChange={(e) => {
                    const newYear = parseInt(e.target.value);
                    if (newYear) {
                      dispatch({ type: 'changeStartYear', payload: newYear });
                    }
                  }}
                >
                  {YEAR_OPTIONS}
                </select>
                至
                <select
                  value={endYear}
                  onChange={(e) => {
                    const newYear = parseInt(e.target.value);
                    if (newYear) {
                      dispatch({ type: 'changeEndYear', payload: newYear });
                    }
                  }}
                >
                  {YEAR_OPTIONS}
                </select>
                <br />
                强度等级≥
                <select
                  value={minLevel}
                  onChange={(e) => {
                    const newLevel = e.target.value;
                    if (newLevel) {
                      dispatch({ type: 'changeMinLevel', payload: newLevel });
                    }
                  }}
                >
                  {LEVEL_OPTIONS}
                </select>
                <br />
                显示样式：
                <select
                  value={showMask}
                  onChange={(e) => {
                    const newMask = parseInt(e.target.value);
                    if (newMask) {
                      dispatch({ type: 'changeShowMask', payload: newMask });
                    }
                  }}
                >
                  <option value={3}>点+线</option>
                  <option value={1}>只显示线</option>
                  <option value={2}>只显示点</option>
                </select>
                <br />
                <input
                  type="checkbox"
                  id="showMarker"
                  checked={showMarker}
                  onChange={() => {
                    dispatch({ type: 'toggleShowMarker' });
                  }}
                />
                <label htmlFor="showMarker">显示点</label>
                <input
                  type="checkbox"
                  id="showLine"
                  checked={showLine}
                  onChange={() => {
                    dispatch({ type: 'toggleShowLine' });
                  }}
                />
                <label htmlFor="showLine">显示线</label>
              </div>
            )}
            {selected ? (
              <TyphoonInfo
                data={data[selected]}
                onClick={() => dispatch({ type: 'unselect' })}
              />
            ) : (
              <div className="TyList">
                {tyIds.map((tyId) => (
                  <div
                    key={tyId}
                    className={classNames({
                      TyItem: true,
                      TyActive: data[tyId]?.active,
                    })}
                    onClick={() => {
                      dispatch({ type: 'select', payload: tyId });
                      const { lat, lon } = data[tyId]?.path?.slice(-1)[0] || {};
                      if (lat && lon) {
                        mapRef.current.leafletElement.panTo([lat, lon]);
                      }
                    }}
                  >
                    <div>
                      {data[tyId]?.id6}{' '}
                      <span style={{ color: '#66f' }}>
                        {data[tyId]?.active ? '过程中' : ''}
                      </span>
                    </div>
                    <div>{data[tyId]?.nameZh || data[tyId]?.nameEn}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {topic === 'FL' ? (
          <div className="Panel">
            <div>水文站点数据</div>
            <table className="floodTable">
              <thead>
                <tr>
                  <th>站点</th>
                  <th>警戒水位</th>
                  <th>历史最高水位</th>
                  <th>最高水位流量</th>
                </tr>
              </thead>
              <tbody>
                {hydroStations.map(
                  ({ code, lat, lon, name, wrz, z_max, z_max_q }) => (
                    <tr
                      key={code}
                      onClick={() => {
                        mapRef.current.leafletElement.panTo([lat, lon]);
                        // mapRef.current.center([lat, lon]);
                      }}
                    >
                      <td title={code}>{name}</td>
                      <td className="CellRight">{wrz ? `${wrz}米` : '--'}</td>
                      <td className="CellRight">
                        {z_max ? `${z_max}米` : '--'}
                      </td>
                      <td className="CellRight">
                        {z_max_q ? `${z_max_q}米³/秒` : '--'}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        ) : null}

        {topic === 'SEARCH' ? (
          <SearchPanel
            currentKeyword={currentKeyword}
            dispatch={dispatch}
            mapRef={mapRef}
            searchResult={searchResult}
            currentPOI={currentPOI}
          />
        ) : null}

        <div className="Menu">
          <span
            className={classNames({ Item: true, selected: topic === 'TY' })}
            onClick={toggleTyphoon}
          >
            台风信息
          </span>
          <span
            className={classNames({ Item: true, selected: topic === 'FL' })}
            onClick={toggleFlood}
          >
            洪水信息
          </span>
          <span
            className={classNames({ Item: true, selected: topic === 'SEARCH' })}
            onClick={toggleSearch}
          >
            地名搜索
          </span>
          {/* <div className="TopicSelect">
            <span
              className={classNames({ selected: topic === 'TY' })}
              onClick={() => {
                // dispatch({ type: 'unselect' });
                setTopic('TY');
              }}
            >
              台风
            </span>
            <span
              className={classNames({ selected: topic === 'FL' })}
              onClick={() => {
                dispatch({ type: 'unselect' });
                setTopic('FL');
              }}
            >
              洪水
            </span>
          </div> */}
        </div>
        {loading ? (
          <div className="loading-mask">
            <div>加载中……</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default App;
